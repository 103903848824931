<template>
    <div class="section_right">
        <!--section_right-->
        <div class="sub_top">
            <!--sub_top-->
            <div class="sub_title">
				<i class="far fa-comments-alt text-primary"></i>{{$t('inquiry.1')}}</div>
        </div>
        <!--sub_top end-->
        <div class="section_right_inner">
            <!--section_right_inner-->
            <div class="notice_table02">
                <table style="width:100%" class="view_table">
                    <thead>
                        <tr>
                            <th>
                                <div class="viewtitle_l">{{title}}</div>
                                <div class="viewtitle_r">
                                    <span>{{date}}</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <pre class="board_pre" v-html="memo"></pre>
                            </td>
                        </tr>
                    </tbody>
                    <thead v-if="reply =='Y'">
                        <span class="p-2">{{$t('inquiry.13')}}</span>
                        <tr >
                            
                            <td>
                                
                                <pre class="board_pre" v-html="answer"></pre>
                            </td>
                        </tr>
                    </thead>
                </table>
                <input type="button" class="listgo_btn mt-4" :value="`${$t('shared.5')}`" @click="$router.push({path:'/'+$i18n.locale+'/inquiry'})">
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
    data(){
        return{
            code : this.$route.params.code,
            title: '', 
            writer: '', 
            date: '',
            memo: '',
            answer : '',
            reply : '',
        }
    },
    mounted (){
        this.GetNoticeDetail();
    },
    methods : {
        GetNoticeDetail(){
			const code = this.code;
			const body = {code};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/board/GetInquiryDetail`,{req}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)

                            this.title = body.info.title;
                            this.memo = body.info.memo;
                            this.date = body.info.date;
                            this.reply = body.info.reply;
                            this.answer = body.info.answer;
                        }
                    }

                }
            ).catch(() => {});
        }
    }
}
</script>

<style>

</style>